<template>
    <div class="login-container">
        <div v-if="form" class="login">
            <b-card>
                <img src="@/common/assets/img/logo-t-small.png" class="logo-login img-fluid">
                <FormTemplate v-if="form" :form="form" class="form-login">
                    <b-form-group class="label-for-xl" label="E-Mail Address" label-for="inputEmail">
                        <b-form-input
                            type="text" id="inputEmail" class="form-control form-control-xl"
                            placeholder="enter your e-mail address"
                            v-model="form.data.username"
                            :state="form.states.username"
                        />
                        <b-form-invalid-feedback v-if="form.errors">{{ form.errors.username }}</b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group class="label-for-xl" label="Password" label-for="inputPassword">
                        <b-form-input
                            type="password" id="inputPassword" class="form-control form-control-xl"
                            placeholder="enter your password"
                            v-model="form.data.password"
                            :state="form.states.password"
                        />
                        <b-form-invalid-feedback v-if="form.errors">{{ form.errors.password }}</b-form-invalid-feedback>
                    </b-form-group>
                    <template v-slot:button.save.text>Login</template>
                </FormTemplate>
            </b-card>
        </div>
        <div v-else>Please wait...</div>
    </div>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    components: {
        FormTemplate
    },
    data(){
        return {
            form: null
        };
    },
    methods: {
      getForm(){
        return new Form({username: "",password: ""},{
            onSubmit: (form) => {
                return this.$api.post('login', {
                    data: {username: form.get('username'),password: form.get('password')}
                })
            },
            onSuccess: (form, {data}) => {
                //console.log('login@onSuccess',data);
                //this.$cookies.set('us_token',data.accessToken,{ expires: '1Y' });
                //localStorage.setItem('us_token', data.accessToken);
                this.$store.dispatch('User/Auth/setCookie', data.accessToken);
                document.location.href = this.$router.resolve({name: "app.dashboard"}).href;
            },
            onError: function(form, err){
              if(err.data){
                let {errors} = err.data;
                if(errors.authentication){
                  form.errors.defaultError = errors.authentication;
                }
              }
              console.log('login@onError',err);
            }
        });
      }
    },
    mounted(){
      this.$store.dispatch('User/Auth/isLoggedIn').then(isLoggedIn => {
        if(isLoggedIn){
          document.location.href = this.$router.resolve({name: "app.dashboard"}).href;
        }
        else{
          this.form = this.getForm();
        }
      }).catch(() => {
        this.form = this.getForm();
      });
    },
};
</script>
